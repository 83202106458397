.footer {
  margin-top: var(--space-l-xl);
  
  @include mq-l {
    padding: 0 var(--space-s-m);
  }
}

.footer__inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: var(--space-s-m) var(--space-l);

  @include mq-s {
    background-color: rgb(var(--color-blue-light));
    flex-flow: row nowrap;
  }

  @include mq-l {
    border-radius: 1vw 1vw 0 0;
  }
}

.footer__logo {
  svg {
    width: 120px;
  }
}

.footer__navigation {
  margin-top:  var(--space-m);

  @include mq-s {
    margin-top: unset;
    margin-left: var(--space-m);
  }
      
  ul {
    text-align: center;

    @include mq-s {
      display: flex;
      column-gap: var(--space-m);
     
    }

    > * + * {
      margin-top:  var(--space-s);

      @include mq-s {
        margin-top: unset;
      }
    }
  }
}