.navigation {
  background-color: rgb(var(--color-blue-light));
  position: fixed;
  // top: 80px;
  top: var(--space-3xl);
  right: 0;
  z-index: 90;
  transform: translateZ(0);
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
  width: 100vw;
  height: auto;
  padding: var(--space-m) var(--space-s-m);
  text-align: right;

  &.is-active {
    transform: translateX(0);
  }

  @include mq-s {
    max-width: toRem(400);
  }

  @include mq-m {
    top: 90px;
   
  }

  @include mq-l {
    all: unset;
    display: block;
  }
}

.navigation__wrapper {
  
  @include mq-l {
    display: flex;
    justify-content: flex-end;
    column-gap: var(--space-2xl);
  }

  * + * {
    margin-top: var(--space-s);

    @include mq-l {
      margin-top: unset;
    }
  }
}

.navigation__link {
  &.is-active,
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.01em;
    text-underline-offset: 0.25em;
  }
}

