:root {
    --color-blue: 0, 89, 153;
    --color-blue-dark: 0, 37, 64;
    --color-blue-light: 0, 111, 191;
    --color-blue-lightest: 191, 228, 255;
    
    /* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1280,18,1.333,3,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
    --step--1: clamp(0.83rem, calc(0.83rem + 0.02vw), 0.84rem);
    --step-0: clamp(1.00rem, calc(0.96rem + 0.21vw), 1.13rem);
    --step-1: clamp(1.20rem, calc(1.10rem + 0.50vw), 1.50rem);
    --step-2: clamp(1.44rem, calc(1.25rem + 0.93vw), 1.7rem); // custom value for max
    --step-3: clamp(1.73rem, calc(1.42rem + 1.56vw), 2.5rem); // custom value for max

    /* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1280,18,1.333,3,1,&s=0.75|0.5,1.5|2|3|4|5,xs-2xl&g=s,l,xl,12 */
    --space-2xs: clamp(0.50rem, calc(0.48rem + 0.10vw), 0.56rem);
    --space-xs: clamp(0.75rem, calc(0.71rem + 0.21vw), 0.88rem);
    --space-s: clamp(1.00rem, calc(0.96rem + 0.21vw), 1.13rem);
    --space-m: clamp(1.50rem, calc(1.44rem + 0.31vw), 1.69rem);
    --space-l: clamp(2.00rem, calc(1.92rem + 0.42vw), 2.25rem);
    --space-xl: clamp(3.00rem, calc(2.88rem + 0.63vw), 3.38rem);
    --space-2xl: clamp(4.00rem, calc(3.83rem + 0.83vw), 4.50rem);
    --space-3xl: clamp(5.00rem, calc(4.79rem + 1.04vw), 5.63rem);

    /* One-up pairs */
    --space-2xs-xs: clamp(0.50rem, calc(0.38rem + 0.63vw), 0.88rem);
    --space-xs-s: clamp(0.75rem, calc(0.63rem + 0.63vw), 1.13rem);
    --space-s-m: clamp(1.00rem, calc(0.77rem + 1.15vw), 1.69rem);
    --space-m-l: clamp(1.50rem, calc(1.25rem + 1.25vw), 2.25rem);
    --space-l-xl: clamp(2.00rem, calc(1.54rem + 2.29vw), 3.38rem);
    --space-xl-2xl: clamp(3.00rem, calc(2.50rem + 2.50vw), 4.50rem);
    --space-2xl-3xl: clamp(4.00rem, calc(3.46rem + 2.71vw), 5.63rem);

    /* Custom pairs */
    --space-xs-2xl: clamp(0.75rem, calc(-0.50rem + 6.25vw), 4.50rem);
}

html {
    height: 100%;
}

body {
    background-image: linear-gradient(
        180deg,
        hsl(205deg 100% 30%) 0%,
        hsl(205deg 100% 28%) 22%,
        hsl(205deg 100% 26%) 33%,
        hsl(206deg 100% 24%) 42%,
        hsl(205deg 100% 21%) 50%,
        hsl(205deg 100% 19%) 58%,
        hsl(205deg 100% 17%) 67%,
        hsl(206deg 100% 15%) 78%,
        hsl(205deg 100% 13%) 100%
    );
    background-attachment: fixed;
    min-height: 100%;
    max-width: 90em;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: white;
    line-height: 1.4;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @include mq-l {
        // hyphens: unset;
    }
}

img {
    height: auto;
    max-width: 100%;
}
