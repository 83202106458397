.accordion {
  
  max-width: 80ch;

  + .accordion {
    margin-top: var(--space-m-l);
  }
}

.accordion__inner {
  // margin-top: var(--space-xs-s);

  details {
    &:not(:first-child) {
      border-top: 1px dashed white;
    }
  }

  summary {
    list-style: none;
    position: relative;
    padding: 0.25em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content:'+';
      font-size: var(--step-3);
    }
  }

  details[open] summary::after {
    content:'-'; 
  }
}
