.breadcrumb {
  padding: var(--space-s-m) var(--space-s-m) var(--space-xs);

  @include mq-l {
    margin-top: var(--space-s-m);
    padding: var(--space-s-m) var(--space-s-m) var(--space-xs) toRem(60);
  }
  
  ul {
      display: flex;
      gap: 10px;
  }

  li {
    display: flex;
    align-items: center;

    &:not(:last-child):after {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 16l6-6-6-6'/%3E%3C/svg%3E");
      margin-left: 0.5em;
      display: inline-block;
      width: 1em;
      height: 1em;
    }

    &:last-child {
      pointer-events: none;
    }
  }
}