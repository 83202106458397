.header {
  @include mq-l {
    padding: 0 var(--space-s-m);
  }
}

.header__inner {
  padding: var(--space-2xs) var(--space-s-m);
  display: flex;
  justify-content: space-between;
  
  @include mq-s {
    align-items: flex-start;
  }

  @include mq-l {
    padding: var(--space-2xs) var(--space-s-m) var(--space-s-m);
    justify-content: unset;
    flex-wrap: wrap;
    background-color: rgb(var(--color-blue-light));
    border-radius: 0 0 1vw 1vw;
  }
}

.header__logo {
  flex: 0 0 clamp(150px, 30%, 300px);

  @include mq-l {
    margin-top: toRem(20);
  }
}

.header__disturber {
  display: none;
  position: relative;
  background-color: rgba(var(--color-blue-dark), 0.4);
  border-radius: 5px;
  padding: toRem(5) toRem(10);
  margin-top: toRem(8);

  @include mq-s {
    display: block;
    margin-left: auto;
    margin-right: var(--space-l-xl);
  }

  @include mq-m {
    margin-right: var(--space-xl-2xl);
    margin-top: toRem(20);
  }

  @include mq-l {
    padding: toRem(10) toRem(15) toRem(10) toRem(30);
  }
 
  svg {
    width: 20px;
    position: absolute;
    top: -10px;
    left: -10px;

    @include mq-m {
      width: 30px;
      top: -20px;
      left: -20px;
    }

    @include mq-l {
      width: 40px;
      top: -20px;
      left: -25px;
    }
  }

  span {
    font-size: var(--step--1);

    @include mq-m {
      font-size: var(--step-0);
    }
  }
}

.header__navigation {

  margin-top: toRem(8);

  @include mq-m {
    margin-top: toRem(25);
  }
 
  @include mq-l {
    flex: 0 0 90%;
    margin-top: toRem(-30);
  }

  > button {
    @include mq-l {
      display: none;
    }
  }
}
