.hero {
  margin-top: var(--space-xl-2xl);

  @include mq-l {
    margin-top: var(--space-l-xl);
  }
}

.hero__header {
  max-width: 110ch;
  padding: 0 var(--space-l-xl);
}

.hero__inner {
  display: grid;
  gap: var(--space-m-l) 0;
  margin-top: var(--space-m-l);

  @include mq-m {
    margin-top: unset;
    padding: var(--space-l-xl) var(--space-l-xl) var(--space-m-l);
    grid-template-columns: minmax(250px, 730px) minmax(40ch, 50ch);
    gap: 0 var(--space-l-xl);
  }
}

.hero__image {
  @include mq-m {
    margin-top: var(--space-xs);
  }
}

.hero__content {
  padding: 0 var(--space-s-m) var(--space-m-l);
  text-align: center;

  @include mq-m {
    padding: unset;
    text-align: left;
    margin-top: var(--space-s-m);
  }

  > * + * {
    margin-top: var(--space-m-l);
  }
}

