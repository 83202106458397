button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
}

.button {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;

  svg {
    width: 0.8em;
    height: 0.8em;
  }
}

.button--primary {
  font-size: var(--step-1);
  border: 1px solid white;
  border-radius: 0.3em;
  padding: 0.3em 0.8em;

  &:hover {
    background-color: rgb(var(--color-blue-dark));
  }
}

.button--secondary {
  font-size: var(--step-1);
  text-decoration: underline;
  text-decoration: underline;
  text-decoration-thickness: 0.01em;
  text-underline-offset: 0.25em;

  &:hover {
    text-decoration: none;
  }
}

.button--navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button--navigation__lines {
  position: relative;
  width: 30px;
  height: 42px;
}

.button--navigation__line {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  transition: 250ms all ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 3px;
    background: white;
    transition: all 250ms ease-in-out;
  }

  &::before {
    left: 0;
    transform-origin: right center;
  }

  &::after {
    right: 0;
    transform-origin: left center;
  }

  &.is-line-1 {
    transform: translateY(14px);
  }

  &.is-line-2 {
    transform: translateY(26px);
  }
}

.button--navigation.is-active {
    .is-line-1 {
      transform: translateY(20px);

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    .is-line-2 {
      transform: translateY(20px);

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }


