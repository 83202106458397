.teaser-3col {
  margin-top: var(--space-l-xl);
 
}

.teaser-3col__inner {
  padding: var(--space-m-l) var(--space-s-m) var(--space-m-l);

  @include mq-m {
    padding: var(--space-m-l) var(--space-l-xl) var(--space-m-l);
  }

  @include mq-l {
    padding: var(--space-m-l) var(--space-2xl-3xl) var(--space-m-l);
  }
}

.teaser-3col__items {
  margin-top: var(--space-l-xl);
  margin-bottom: var(--space-l-xl);
  display: grid;
  gap: var(--space-m-l) 0;
 
  @include mq-m {
    gap: 0 var(--space-m-l);
    grid-template-columns: repeat(2, 1fr);

  }

  @include mq-l {
    grid-template-columns: repeat(3, 1fr);
  }
}

.teaser-3col__item {
  //
}
