.article {
  @include mq-l {
    padding: 0 var(--space-s-m);
  }
}

.article__inner {
  background-color: rgba(var(--color-blue), 0.4);
  padding-top: var(--space-m-l);
  padding-bottom: var(--space-l-xl);
  
  @include mq-l {
    border-radius: 1vw;
  }
}

.article__headings {
  
  h1 {
    padding-left: var(--space-s-m);
    padding-right: var(--space-s-m);

    @include mq-m {
      padding-left: var(--space-l-xl);
      padding-right: unset;
    }
  }

  h2 {
    max-width: 50ch;
    padding-left: var(--space-s-m);
    padding-right: var(--space-s-m);
    margin-top: var(--space-m-l);

    @include mq-m {
      padding-left: var(--space-l-xl);
      padding-right: unset;
    }
  }
}

.article__image {
  margin-top: var(--space-m-l);

  @include mq-m {
    max-width: unset;
    margin-left: 6%;
    margin-bottom: toRem(10);
    float: right;
    width: 36%;
    padding-right: var(--space-l-xl);
  }

  @include mq-l {
    width: 44%;
  }
}

.article__content {
  padding: 0 var(--space-s-m);
  margin-top: var(--space-l-xl);
  
  @include mq-m {
    padding: 0 var(--space-l-xl);
  }
}