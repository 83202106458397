h1,
.is-h1 {
  font-size: var(--step-3);
}

h2,
.is-h2 {
  font-size: var(--step-2);
}

h3,
.is-h3 {
  font-size: var(--step-1);
}

p,
.is-text {
  font-size: var(--step-0);
  line-height: 1.5;
}

ul,
ol {
  font-size: var(--step-0);
}

small,
.is-text-small {
  font-size: var(--step--1);
}

.has-icon {
  display: flex;
  column-gap: 0.75em;
  align-items: center;

  svg {
    width: 1.75em;
  }
}
