.grid {
  display: grid;
  align-items: start;
  grid-template-columns: var(--space-s-m) minmax(80vw, 100%) var(--space-s-m);
  row-gap: var(--space-l-xl);

  @include mq-m {
    grid-template-columns: var(--space-s-m) repeat(12, 1fr) var(--space-s-m);
    row-gap: unset;
  }
}

.grid__item {
  grid-column-start: 2;

  @include mq-m {
    grid-row: 1;
  }

  &--bleed-left {
    grid-column: 1 / span 3; 
  }

  @include mq-m {
    &--span-2 { grid-column-end: span 2; }
    &--span-3 { grid-column-end: span 3; }
    &--span-4 { grid-column-end: span 4; }
    &--span-5 { grid-column-end: span 5; }
    &--span-6 { grid-column-end: span 6; }
    &--span-7 { grid-column-end: span 7; }
    &--span-8 { grid-column-end: span 8; }
    &--span-9 { grid-column-end: span 9; }
    &--span-10 { grid-column-end: span 10; }
    &--span-11 { grid-column-end: span 11; }
    &--span-12 { grid-column: 2 / span 12; }

    &--start-0 { grid-column-start: 1; }
    &--start-1 { grid-column-start: 2; }
    &--start-2 { grid-column-start: 3; }
    &--start-3 { grid-column-start: 4; }
    &--start-4 { grid-column-start: 5; }
    &--start-5 { grid-column-start: 6; }
    &--start-6 { grid-column-start: 7; }
    &--start-7 { grid-column-start: 8; }
    &--start-8 { grid-column-start: 9; }
    &--start-9 { grid-column-start: 10; }
    &--start-10 { grid-column-start: 11; }
  }
}