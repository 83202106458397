@mixin mq-s {
    @media all and (min-width: $bp-s + 1) {
        @content;
    }
}
@mixin mq-m {
    @media all and (min-width: $bp-m + 1) {
        @content;
    }
}
@mixin mq-l {
    @media all and (min-width: $bp-l + 1) {
        @content;
    }
}
