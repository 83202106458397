.teaser {
  background-color: rgba(var(--color-blue), 0.4);
  margin-top: var(--space-l-xl);

  @include mq-l {
    border-radius: 1vw;
    max-width: 93%;
    margin-left: auto;
    margin-right: auto;
  }
}

.teaser__inner {
  display: grid;
  gap: var(--space-m-l) 0;

  @include mq-m {
    padding: var(--space-l-xl) var(--space-l-xl) var(--space-m-l);
    grid-template-columns: minmax(45ch, 55ch) 7% minmax(200px, auto);
    gap: unset;
    // align-items: center;
  }
}

.teaser__content {
  padding: 0 var(--space-s-m) var(--space-xl-2xl);
  order: 2;

  @include mq-m {
    padding: unset;
    order: 1;
  }

  > * + * {
    margin-top: var(--space-m-l);
  }
}

.teaser__image {
  order: 1;

  @include mq-m {
    order: 2;
    grid-column: 3 / 4;
  }
}