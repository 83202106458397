.text {
 //
}

.text__rte {
  margin-top: var(--space-m-l);
  max-width: 80ch;
  padding-bottom: var(--space-m-l);

  .text--reset & {
    margin-top: unset;
  }

  > * + * {
    margin-top: var(--space-s-m);
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: 0.01em;
    text-underline-offset: 0.25em;
  }

  ul {
    list-style: circle;
    padding-left: 2em;
  }

  li {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
}
